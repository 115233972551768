<script setup lang="ts">
const { queue, removeNotification } = useNotification()

const showSettingsDialog = ref(false)
function showSettings() {
  showSettingsDialog.value = true
}
</script>

<template>
  <Teleport to="body">
    <TransitionGroup
      tag="ul"
      name="notification"
      class="fixed bottom-5 right-5 z-50 space-y-2"
    >
      <li
        v-for="notification in queue"
        :key="notification.id"
        class="flex justify-end"
      >
        <NotificationsGeneric
          :notification="notification"
          @show-settings="showSettings()"
          @close="removeNotification(notification.id)"
        />
      </li>
    </TransitionGroup>
  </Teleport>

  <SettingsDialog
    v-if="showSettingsDialog"
    @hide="showSettingsDialog = false"
  />
</template>

<style>
.notification-enter-active,
.notification-leave-active {
  transition: all 0.5s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translatex(-30px);
}
</style>
