import { GraphQLClient } from 'graphql-request'

export default defineNuxtPlugin(async (nuxtApp) => {
  const userStore = useUserStore()
  const { addNotification } = useNotification()

  const client = new GraphQLClient(`${location.protocol}//${location.host}/api/graphql/`, {
    headers() {
      return {
        'Language-Code': userStore.language,
        'Authorization': authorizationHeader.value,
      }
    },
    priority: 'high',
    responseMiddleware: (response) => {
      if ('errors' in response && response.errors) {
        addNotification({
          type: 'error',
          title: (nuxtApp.$i18n as any).t('graphqlError'),
          message: response.errors.at(0)?.message,
        })
      }
    },
    errorPolicy: 'all',
  })

  return {
    provide: {
      gql: client,
    },
  }
})
