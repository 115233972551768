<script setup lang="ts">
import { CheckCircleIcon, Cog6ToothIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid'
import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon } from '@heroicons/vue/24/outline'
import type { Notification } from '~/composables/useNotification'

const props = defineProps<{
  notification: Notification
}>()
const emit = defineEmits(['close', 'showSettings'])

const { copy, copied } = useClipboard({ source: props.notification.message, legacy: true })

const backgroundColor = computed(() => {
  switch (props.notification.type) {
    case 'info':
      return 'bg-info'
    case 'success':
      return 'bg-success'
    case 'warning':
      return 'bg-warning'
    case 'error':
      return 'bg-danger'
  }
})
</script>

<template>
  <div class="z-40 flex shadow-sm">
    <div
      class="h-full w-2 rounded-l-lg"
      :class="backgroundColor"
    />
    <div class="flex items-center rounded-r-lg bg-surface py-2 pr-2 text-sm text-on-surface">
      <InformationCircleIcon
        v-if="props.notification.type === 'info'"
        class="mx-3 size-6 text-info"
      />
      <CheckCircleIcon
        v-else-if="props.notification.type === 'success'"
        class="mx-3 size-6 text-success"
      />
      <ExclamationTriangleIcon
        v-else-if="props.notification.type === 'warning'"
        class="mx-3 size-6 text-warning"
      />
      <ExclamationCircleIcon
        v-else-if="props.notification.type === 'error'"
        class="mx-3 size-6 text-danger"
      />
      <div>
        <div
          v-if="props.notification.title"
          class="font-semibold"
        >
          {{ props.notification.title }}
        </div>
        <div v-if="props.notification.message">
          {{ props.notification.message }}
        </div>
      </div>
      <hr class="ml-4 h-full border-l-2 border-notification">
      <button
        v-if="props.notification.type === 'error'"
        type="button"
        class="ml-1 rounded-md p-2 hover:bg-notification-icon-hover"
        @click="copy()"
      >
        <ClipboardDocumentCheckIcon
          v-if="copied"
          class="size-6"
        />
        <ClipboardDocumentIcon
          v-else
          class="size-6"
        />
      </button>
      <button
        type="button"
        class="mx-1 rounded-md p-2 hover:bg-notification-icon-hover "
        @click="emit('showSettings')"
      >
        <Cog6ToothIcon class="size-6" />
      </button>
      <hr class="h-full border-l-2 border-notification">
      <button
        type="button"
        class="ml-1 rounded-md p-2 hover:bg-notification-icon-hover "
        @click="emit('close')"
      >
        <XCircleIcon class="size-6" />
      </button>
    </div>
  </div>
</template>
