type DismissibleNotificationType = 'info' | 'success' | 'warning'
type UndismissibleNotificationType = 'error'

export type Notification = {
  type: DismissibleNotificationType
  durationInSeconds?: number
  title?: string
  message: string
} | {
  type: UndismissibleNotificationType
  durationInSeconds?: never
  title?: string
  message?: string
  error?: object
}

type NotificationWithId = Notification & {
  id: string
  startDate: Date
}

function createRandomUUID() {
  return 'xxxx-xxxx-xxx-xxxx'.replace(/[x]/g, () => {
    const r = Math.floor(Math.random() * 16)
    return r.toString(16)
  })
}

function checkUserNotificationSettings(notification: Notification) {
  const userStore = useUserStore()
  const userNotificationSetting = userStore.notifications

  // Info- / Success-Notifications
  if (userNotificationSetting.toLowerCase() === 'info' && (notification.type === 'info' || notification.type === 'success'))
    return true

  // Warning-Notifications
  if (notification.type === 'warning' && (
    userNotificationSetting.toLowerCase() === 'info'
    || userNotificationSetting.toLowerCase() === 'warning'))
    return true

  // Error-Notifications
  if (notification.type === 'error')
    return true

  return false
}

const queue = reactive<NotificationWithId[]>([])

export function useNotification() {
  const updateQueueInInterval = () => {
    queue.forEach((notification, index) => {
      if (!notification.durationInSeconds)
        return

      const shouldRemove = notification.startDate.getTime() + notification.durationInSeconds * 1000 < Date.now()
      if (shouldRemove)
        queue.splice(index, 1)
    })
  }

  useIntervalFn(updateQueueInInterval, 1000)

  const removeNotification = (id: string) => {
    const index = queue.findIndex(notification => notification.id === id)
    if (index === -1) {
      console.error(`Could not find notification with id ${id}`)
      return
    }
    queue.splice(index, 1)
  }

  const addNotification = (notification: Notification) => {
    const notificationWithId: NotificationWithId = {
      ...notification,
      id: createRandomUUID(),
      startDate: new Date(),
    }

    if (notification.type !== 'error' && !notification.durationInSeconds)
      notificationWithId.durationInSeconds = 3

    if (notification.type === 'error' && notification.error)
      notificationWithId.message = notification.error.message ?? JSON.stringify(notification.error)

    if (checkUserNotificationSettings(notification))
      queue.push(notificationWithId)
  }

  return {
    queue: readonly(queue),
    addNotification,
    removeNotification,
  }
}
