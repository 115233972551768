export enum FileHistoryType {
  Create = 'CREATE',
  Upload = 'UPLOAD',
  Download = 'DOWNLOAD',
  Checkin = 'CHECKIN',
  Checkout = 'CHECKOUT',
  SetRevision = 'SET_REVISION',
  DownloadOldRevision = 'DOWNLOAD_OLD_REVISION',
}

export enum DashboardAccordions {
  DNC_OPTIMIZATIONS,
  REVISION_INCOMPATIBILITIES,
}
